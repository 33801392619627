.container {
    padding-right: 20px;
    padding-left: 20px;
    margin-right: auto;
    margin-left: auto;
  }
  @media (min-width: 768px) {
    .container {
      width: 750px;
    }
  }
  @media (min-width: 992px) {
    .container {
      width: 970px;
    }
  }
  @media (min-width: 1200px) {
    .container {
      padding-right: 0px;
      padding-left: 0px;
      width: 1170px;
    }
  }

  @media (min-width: 1440px) {
    .container {
      padding-right: 0px;
      padding-left: 0px;
      width: 1240px;
    }
  }

  .section-title{
    display: block;
    text-align: center;
    font-weight: 700;
    font-size: font(b3);
    line-height: toRem(34);
    color: color(--txt);

    @include respond-to(mobile){
      font-size: toRem(24);
    }
  }