.ecosystem {
  margin-top: toRem(100);
  background: color(--txt, 0.02);
  padding: toRem(60) toRem(77);
  box-shadow: inset toRem(1) toRem(-1) 0 color(--txt, 0.1);
  backdrop-filter: blur(toRem(40));
  border-radius: toRem(40) toRem(40) toRem(60) toRem(40);
  display: flex;
  gap: toRem(60);

  &__left {
    &-title {
      display: block;
      background: color(--txt, 0.02);
      box-shadow: inset toRem(1) toRem(-1) 0 color(--txt, 0.1);
      border-radius: toRem(20);
      max-width: toRem(342);
      font-size: font(b2);
      padding: toRem(32) toRem(40);
      line-height: toRem(54);
      font-weight: 500;
    }
  }

  &__right {
    margin-top: toRem(40);
    font-size: font(b3);
    line-height: toRem(34);

    &-title {
      display: block;
      font-weight: 700;
    }

    &-items {
      margin-top: toRem(40);
      display: grid;
      grid-template-columns: repeat(4, toRem(131));
      gap: toRem(40);

      &__item {
        display: flex;
        align-items: center;
        gap: toRem(12);

        &-img {
          @include w-h(toRem(40), toRem(40));
        }

        &-title {
          font-size: font(sm1);
          line-height: toRem(18);
          font-weight: 500;
        }
      }
    }
  }

  @include respond-to(landscape) {
    &__right {
      &-items {
        grid-template-columns: repeat(3, toRem(131));
        gap: toRem(30);
      }
    }
  }

  @include respond-to(tablet) {
    flex-direction: column;
    gap: toRem(40);

    &__right {
      &-items {
        justify-content: space-between;
        grid-template-columns: repeat(3, toRem(131));
        gap: toRem(35);
      }
      &-title {
        text-align: center;
      }
    }

    &__left {
      &-title {
        font-size: font(b4);
        margin: 0 auto;
        text-align: center;
        max-width: toRem(300);
        padding: toRem(32) toRem(63.5);
      }
    }
  }
  @include respond-to(mobile) {
    padding: toRem(43) toRem(25);

    &__right {
      &-items {
        grid-template-columns: repeat(2, toRem(131));
        max-height: toRem(192);
        overflow: hidden;
      }
    }
  }
}

[data-theme="light"] .ecosystem {
  background: color(--white);
  backdrop-filter: none;
  box-shadow: 0 toRem(40) toRem(80) rgba(51, 48, 145, 0.04);

  &__left {
    &-title {
      background: transparent;
      border: toRem(1) solid #bbb9fa;
    }
  }
}
