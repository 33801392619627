.statistics {
  margin-top: toRem(60);
  display: flex;
  background-color: color(--txt, 0.02);
  box-shadow: inset toRem(1) toRem(-1) 0 color(--txt, 0.1);
  backdrop-filter: blur(toRem(40));
  border-radius: toRem(20);
  padding: toRem(44) 0;

  &__item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: toRem(8);
    position: relative;
    width: 100%;

    &::after {
      content: "";
      position: absolute;
      right: toRem(1);
      @include w-h(toRem(1), 100%);
      background: color(--txt, 0.16);
    }

    &:last-of-type {
      &::after {
        display: none;
      }
    }

    & > span {
      display: block;
    }

    &-name {
      color: color(--txt, 0.5);
      font-size: font(sm1);
      line-height: toRem(18);
      font-weight: 400;
    }

    &-value {
      color: color(--txt);
      font-size: font(b3);
      font-weight: 700;
      line-height: toRem(34);
      letter-spacing: 0.04em;
    }
  }

  @include respond-to(mobile){
    flex-wrap: wrap;

    &__item {
      width: 50%;

      &:nth-child(2) {
        &::after {
          display: none;
        }
      }
      &:nth-of-type(3) {
        margin-top: toRem(60);
      }
      &:nth-of-type(4) {
        margin-top: toRem(60);
      }
    }
  }
}

[data-theme='light'] .statistics{
  background: color(--white);
  backdrop-filter: none;
  box-shadow: 0 toRem(40) toRem(80) rgba(51, 48, 145, 0.04);
}