.footer-payment {
  width: toRem(276);

  &__title {
    display: block;
    font-size: font(md2);
    font-weight: 700;
    line-height: toRem(34);
    color: color(--white);
  }

  &__items {
    margin-top: toRem(24);
    display: flex;
    gap: toRem(18);

    &-item {
      @extend %center;
      @include w-h(toRem(80), toRem(52));
      background: color(--white, 0.04);
      box-shadow: inset toRem(1) toRem(-1) 0 color(--white, 0.1);
      border-radius: toRem(8);
    }
  }

  @include respond-to(tablet) {
    width: 100%;
    text-align: center;

    &__items {
      margin-top: toRem(25);
      justify-content: center;
    }
  }

  @include respond-to(mobile) {
    &__title {
      font-size: font(sm3);
    }
  }
}
