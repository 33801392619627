.chains {
  margin-top: toRem(100);
  background: color(--txt, 0.02);
  padding: toRem(60) toRem(77);
  box-shadow: inset toRem(1) toRem(-1) 0 color(--txt, 0.1);
  backdrop-filter: blur(toRem(40));
  border-radius: toRem(20);

  &__items {
    margin-top: toRem(60);
    display: grid;
    grid-template-columns: repeat(auto-fit, toRem(67.3));
    justify-content: space-between;
    gap: toRem(60);

    &-item {
      display: inline-flex;
      width: toRem(67.3);
      flex-direction: column;
      align-items: center;
      gap: toRem(14);

      &__img {
        @include w-h(toRem(40), toRem(40));
      }

      &__title {
        font-size: font(sm1);
        font-weight: 500;
        line-height: toRem(18);
      }
    }
  }

  & .btn {
    margin: 0 auto;
    margin-top: toRem(50);
  }

  &.secondary {
    padding-bottom: toRem(40);
  }

  @include respond-to(mobile) {
    padding: toRem(43) toRem(40);
    &__items {
      grid-template-columns: repeat(3, toRem(66));
      max-height: toRem(305);
      overflow: hidden;
      gap: toRem(36);

      &-item {
        &__title {
          font-size: font(xs1);
          font-weight: 400;
        }
      }
    }
  }
}

[data-theme="light"] .chains {
  background: color(--white);
  backdrop-filter: none;
  box-shadow: 0 toRem(40) toRem(80) rgba(51, 48, 145, 0.04);
}
