html{

  &.active{
    overflow: hidden;
    
    body{
      overflow: hidden;
    }
  }
}

body {
  margin: 0;
  padding: 0;
  font-family: "Ubuntu", sans-serif;
  background-color: color(--bg);
  transition: background-color 0.3s ease;
  color: color(--txt);
  padding-top: toRem(100);

  @include respond-to(tablet){
    padding-top: toRem(80);
  }
}

[data-theme="dark"] body {
  background-image: url("../img/Noise.png");
  background-position: center;
  background-size: cover;
}

html.active[data-theme="light"] .btn{

      box-shadow: none !important;
}

* {
  box-sizing: border-box;
}

input,
textarea,
button,
select,
a {
  -webkit-tap-highlight-color: rgba(0,0,0,0);
}

/* width */
::-webkit-scrollbar {
  width: toRem(6);
}

/* Track */
::-webkit-scrollbar-track {
  background: color(--txt, 0.4);
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: color(--bg, 0.5);
  border-radius: toRem(2);
}
