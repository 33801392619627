.mobile-menu-parent{
    background: color(--bg);
    position: fixed;
    display: flex;
    z-index: 1;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
    padding: toRem(80) toRem(10);
    padding-bottom: toRem(30);
}

.mobile-menu{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    &__items{
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: toRem(20);
    }
}
