.toggle-control {
  display: block;
  position: relative;
  padding-left: toRem(64);
  cursor: pointer;
  font-size: 22px;
  user-select: none;
  @include w-h(toRem(64) ,toRem(34));

  input {
    display: none;
  }

  input:checked ~ .control {
    background-color: color(--txt , 0.06);
    
    &:after {
      left: toRem(33);
      background:  color(--txt);
      box-shadow: none;
    }
  }

  input:checked ~ .theme-icon .light-icon  {
    opacity: 0.5;
  }

  input:checked ~ .theme-icon .dark-icon  {
    opacity: 1;
  }

  .control {
    position: absolute;
    top: 0;
    left: 0;
    @include w-h(toRem(64) ,toRem(34));
    border-radius: toRem(20);
    background-color: rgba(17, 16, 48, 0.06);
    transition: all 0.15s ease-in;

    &:after {
      content: "";
      position: absolute;
      left: toRem(2);
      top: toRem(2);
      box-shadow: 0px 8px 12px rgba(85, 80, 242, 0.2);
      @include w-h(toRem(30) ,toRem(30));
      border-radius: toRem(20);
      background: color(--purple);
      transition: all 0.2s ease-in;
    }
  }
}

.theme-icon{
    display: flex;
    align-items: center;
    @include w-h(toRem(64) ,toRem(34));
    position: absolute;
    left: 0;
    padding-left: toRem(9);
    gap: toRem(16);

    & svg{
        transition: all 0.2s ease;
    }
}

.dark-icon{
    opacity: 0.5;
}

.light-icon{
    opacity: 1;
}