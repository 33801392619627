.header {
  background: color(--white, 0.02);
  box-shadow: inset 0 toRem(-1) 0 color(--white, 0.07);
  backdrop-filter: blur(toRem(40));
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 2;

  &__content {
    padding: toRem(26) 0;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &-left {
      display: flex;
      align-items: center;
      gap: toRem(119);

      &__logo {
        @include w-h(toRem(135), toRem(28));
        cursor: pointer;
      }
    }

    &-right {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: toRem(30);

      &__burger {
        cursor: pointer;
        background: none;
        border: none;
        outline: none;
        display: none;

        & path{
          stroke: color(--txt);
        }
      }
    }
  }

  @include respond-to(tablet) {
    &__content {
      &-right {
        > :not(:last-child) {
          display: none;
        }
        &__burger {
          display: block;
        }
      }
    }
  }
}

.header-border {
  position: fixed;
  top: toRem(100);
  z-index: 1;

  &__content {
    position: absolute;
    left: toRem(253);
    bottom: toRem(-2);
    @include w-h(toRem(38), toRem(16));
    background: color(--purple);
    border-radius: toRem(2);
    z-index: 0;
  }

  @include respond-to(landscape) {
    &__content {
      left: toRem(267);
    }
  }

  @include respond-to(tablet) {
    display: none;
  }
}

[data-theme="light"] .header {
  background: color(--white);
  backdrop-filter: none;
}
