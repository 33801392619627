.step {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: toRem(43);
  margin-top: toRem(40);
  margin-left: toRem(40);

  &::before {
    content: "";
    position: absolute;
    left: toRem(9);
    @include w-h( toRem(2), 100%);
    background-color: color(--purple2);
  }

  &__item {
    display: flex;
    align-items: center;
    gap: toRem(14);
    position: relative;

    &-icon {
      @include w-h(toRem(20), toRem(20));
      border-radius: 50%;
      background-color: color(--purple2);
      @extend %center;
    }

    &-title {
      font-weight: 500;
      font-size: font(xs1);
      line-height: toRem(16);
      color: color(--purple2);
    }
  }

  @include respond-to(tablet){
    flex-direction: row;
    overflow-x: scroll;
    margin-left: 0;

    &::-webkit-scrollbar {
      height: 0;
    }

    &::before {
      left: toRem(50);
      top: toRem(10);
      @include w-h( 77%, toRem(1));
    }

    &__item {
      flex-shrink: 0;
      flex-direction: column;
    }
  }
  @include respond-to(mobile){
    margin-right: toRem(-25);
    padding-right: toRem(20);
    &::before {
      @include w-h( toRem(430), toRem(1));
    }
  }
}
