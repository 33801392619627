.btn {
  font-family: "Ubuntu", sans-serif;
  outline: none;
  display: block;
  border: none;
  cursor: pointer;
  padding: toRem(14) toRem(28);
  border-radius: toRem(12);
  font-size: font(sm2);
  background-color: color(--txt);
  color: color(--txt2);
  font-weight: 500;
  transition: all 0.2s ease-in;

  &:hover {
    background-color: color(--txt, 0.9);
  }

  &:active {
    background-color: color(--txt, 0.7);
  }

  &.primary {
    background-color: color(--purple);
    color: color(--white);

    &:hover {
      background-color: color(--purple, 0.9);
    }

    &:active {
      background-color: color(--purple, 0.7);
    }
  }

  &.secondary {
    background-color: transparent;
    color: color(--txt);
    border: 1px solid color(--txt, 0.2);

    &:hover {
      background-color: color(--txt, 0.05);
    }

    &:active {
      background-color: color(--txt, 0.1);
    }
  }
}

[data-theme="light"] .btn.primary {
  background: #333091;
  box-shadow: 0 toRem(8) toRem(12) rgba(51, 48, 145, 0.2);

  &:hover {
    background-color: #4844a8;
  }

  &:active {
    background-color: #5552bb;
  }
}
