.smart {
  margin-top: toRem(100);

  &__items {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: toRem(30);
    margin-top: toRem(50);

    &-item {
      background: color(--txt, 0.02);
      box-shadow: inset toRem(1) toRem(-1) 0 color(--txt, 0.1);
      backdrop-filter: blur(toRem(40));
      border-radius: toRem(20);
      padding: toRem(43) toRem(38);
      padding-right: toRem(47);
      max-width: toRem(434);

      & > span{
          display: block;
      }

      &__icon {
        width: max-content;
        padding: toRem(17);
        border-radius: 50%;
        background-color: color(--txt, 0.04);
        @extend %center;
      }

      &__title {
        font-size: font(md1);
        color: color(--txt);
        line-height: toRem(26);
        letter-spacing: 0.04em;
        margin-top: toRem(18);
      }

      &__content{
          font-size: font(sm1);
          color: color(--txt , 0.5);
          line-height: toRem(28);
          font-weight: 300;
          margin-top: toRem(12);
      }
    }
  }

  @include respond-to(tablet){
    &__items {

      &-item{
        display: flex;
        flex-direction: column;
        align-items: center;
        max-width: toRem(350);
        padding-right: toRem(38);
      }
    }
  }
}

[data-theme='light'] .smart__items-item{
  background: color(--white);
  backdrop-filter: none;
  box-shadow: 0 toRem(40) toRem(80) rgba(51, 48, 145, 0.04);
}