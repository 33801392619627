.header-nav {
  display: flex;
  position: relative;
  gap: toRem(50);
  &__item {
    transition: all 0.3s ease;
    text-decoration: none;
    color: color(--txt, 0.5);
    font-size: font(sm1);
    font-weight: 300;
    position: relative;

    &:nth-of-type(1) {
      color: color(--txt);
    }

    &:hover{
      color: color(--txt, 0.9);
    }
  }
  
  @include respond-to(tablet) {
    &:not(.mobile) {
      display: none;
    }
    padding: toRem(50) 0;
    flex-direction: column;
    gap: toRem(36);

    &__item {
      padding: toRem(7) 0;
      &.active {
        &::after {
          content: "";
          position: absolute;
          @include w-h(toRem(3), toRem(35));
          background: color(--purple);
          left: toRem(-30);
          top: 50%;
          transform: translate(0, -50%);
        }
        &::before {
          content: '';
          display:inline-block ;
          @include w-h(toRem(6), toRem(25));
          position: absolute;
          left: toRem(-33);
          top: 50%;
          transform: translate(0, -50%);
          background: transparent;
          border-radius: 50% 50% 0 0;
          box-shadow: 0 0 toRem(20) toRem(10) rgba(85, 80, 242, 0.4);
          z-index : 12;
          opacity: 1;
          transition: all 0.3s ease-in-out;
        }
      }
    }
  }
}

[data-theme = 'light'] .header-nav__item.active::before{
    display: none;
}
