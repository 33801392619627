// colors START

:root {
    --purple: 85, 80, 242; //#5550F2
    --purple2: 119, 115, 245; //#7773F5
    --white: 255, 255, 255; //#ffffff
    --txt-blur: 137, 146, 157; //#89929D
    --footer:17, 16, 48 ; //#111030
  }
  
  [data-theme="light"]:root {
    --bg: 246, 246, 251; //#F6F6FB
    --txt: 17, 16, 48; //#111030
    --txt2: 255, 255, 255; //#ffffff
  }
  
  [data-theme="dark"]:root {
    --bg: 17, 16, 48; //#111030
    --txt: 255, 255, 255; //#ffffff
    --txt2: 17, 16, 48 ; //#111030
  }

  // colors END
  
  // fonts START
  
  $fonts: (
    b1: 66,
    b2: 38,
    b3: 30,
    b4: 25,
    md1: 23,
    md2: 21,
    sm1: 16,
    sm2: 17,
    sm3: 18,
    xs1:14,
  );
  
  // fonts END
  
  // responsive START
  
  $breakpoints: (
    "mobile": "(max-width: 767px)",
    "tablet": "(max-width: 991px)",
    "landscape": "(max-width: 1199px)",
  );
  
  // responsive END
  