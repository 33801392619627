.footer-column {
  display: flex;
  gap: toRem(90);
  &__title {
    display: block;
    font-size: font(md2);
    font-weight: 700;
    line-height: toRem(24);
    color: color(--white);
    margin-bottom: toRem(22);
  }

  &__links {
    display: flex;
    flex-direction: column;
    gap: toRem(16);

    &-link {
      font-size: font(sm1);
      line-height: toRem(18);
      color: color(--txt-blur);
      text-decoration: none;
      transition: all 0.15s ease-in;

      &:hover {
        color: color(--white, 0.7);
      }

      &:active {
        color: color(--white, 0.9);
      }
    }
  }

  @include respond-to(landscape) {
    gap: toRem(50);
  }
  @include respond-to(tablet) {
    gap: toRem(75);
  }
  @include respond-to(mobile) {
    flex-wrap: wrap;
  }
}
