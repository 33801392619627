.footer {
  padding: toRem(50) 0 toRem(40) 0;
  background: color(--txt, 0.02);
  box-shadow: inset 0 toRem(1) 0 color(--txt, 0.1);
  backdrop-filter: blur(toRem(40));
  margin-top: toRem(60);

  &__content {
    display: flex;
    gap: toRem(100);
  }

  &__content-left {
    &__logo {
      @include w-h(toRem(135), toRem(28));
    }
  }

  &__content-right {
    flex: 1;
    display: flex;
    justify-content: space-between;
  }

  &__down {
    color: color(--white, 0.5);
    font-size: font(sm1);
    font-weight: 400;
    line-height: toRem(18);
    margin-top: toRem(50);
    display: flex;
    justify-content: space-between;

    &-socials {
      display: flex;
      align-items: center;
      gap: toRem(24);

      &__item {
        cursor: pointer;
        & path {
          transition: all 0.2s ease;
        }

        &:hover path {
          opacity: 0.8;
        }
      }
    }
  }

  @include respond-to(landscape) {
    &__content {
      gap: toRem(60);
    }
  }

  @include respond-to(tablet) {
    &__content {
      flex-direction: column;
      align-items: center;
      gap: toRem(50);

      &-right {
        flex-direction: column-reverse;
        gap: toRem(50);
      }
    }
  }

  @include respond-to(mobile) {
    &__down {
      margin-top: toRem(68);
      flex-direction: column;
      align-items: center;
      gap: toRem(18);
    }
  }
}

[data-theme="light"] .footer {
  background: color(--footer);
  backdrop-filter: none;
  box-shadow: none;
}
