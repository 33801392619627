.info {
  margin-top: toRem(20);
  display: flex;
  justify-content: space-between;
  align-items: center;

  &__left {
    display: flex;
    flex-direction: column;
    max-width: toRem(621);
    position: relative;

    &::after {
      content: "";
      position: absolute;
      @include w-h(toRem(150), toRem(150));
      background-color: color(--purple);
      opacity: 0.6;
      filter: blur(toRem(80));
      z-index: -1;
      left: toRem(-20);
      top: 0;
    }

    &-title {
      font-weight: 700;
      font-size: font(b1);
      line-height: 110%;
      margin: 0;
      margin-bottom: toRem(20);
      padding-left: toRem(8);
    }

    &-info {
      color: color(--txt, 0.5);
      font-weight: 300;
      font-size: font(sm1);
      line-height: toRem(30);
      margin: 0;
      margin-bottom: toRem(30);
    }

    &-btns {
      display: flex;
      gap: toRem(16);
    }
  }

  &__right {
    &-img {
      @include w-h(toRem(495), toRem(400));
    }
  }

  @include respond-to(landscape) {
    &__left {
      &-title {
        font-size: toRem(45);
      }
    }
  }

  @include respond-to(tablet) {
    &__right {
      &-img {
        @include w-h(toRem(395), toRem(300));
      }
    }

    &__left {

      &::after {
        display: none;
      }

      &-title {
        font-size: toRem(33);
      }

      &-info {
        font-size: font(xs1);
        line-height: toRem(21);
      }
    }
  }

  @include respond-to(mobile) {
    &__right {
      display: none;
    }

    &__left{
      margin: 0 auto;
      max-width: toRem(340);
      &-btns {
        justify-content: center;
      }

      &-title {
        font-size: toRem(35);
        margin: 0 auto;
        text-align: center;
        max-width: 100%;
        padding: toRem(20) toRem(0);
      }

      &-info {
        text-align: center;
        padding: 0 toRem(10);
      }
    }
  }
}

[data-theme="light"] .info__left::after {
  display: none;
}
